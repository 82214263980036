import { Button } from '@tradewarsio/ui';

function LobbyScreen() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', fontFamily: 'sans-serif' }}>
      <h1 style={{ fontSize: '3rem', marginBottom: '2rem' }}>TradeWarsIO</h1>
      <div style={{ marginBottom: '2rem', fontSize: '1.2rem' }}>
        Online Players: -
      </div>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <Button variant="contained" disabled>
          Join Lobby
        </Button>
        <Button variant="outlined">
          Instructions
        </Button>
      </div>
    </div>
  );
}

export default LobbyScreen; 