import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

// Keep our own ButtonProps interface for potential future customization
// or mapping to MuiButtonProps if needed.
interface ButtonProps extends MuiButtonProps {
  // Add any custom props specific to your design system here
}

function Button(props: ButtonProps) {
  return (
    <MuiButton {...props} />
  );
}

export default Button; 